<template>
  <div class="animated fadeIn">

    <b-card>
      <h3 class="mb-2">
        {{ $route.meta.breadcrumb[1].text }}
      </h3>
      <b-tabs
        pills
      >
        <b-tab
          lazy
          title="Profile"
        >
          <profile />
        </b-tab>
        <b-tab
          lazy
          :active="$route.params.defaultActive === 'bank'"
          title="Akun Bank"
        >
          <bank />
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BTabs, BTab,
} from 'bootstrap-vue'
import api from '@/utils/api'
import Profile from './profile.vue'
import Bank from './bank/index.vue'

export default {
  name: 'FormProfile',
  metaInfo: {
    title: 'Profile',
  },
  components: {
    BCard,
    BTabs,
    BTab,

    Profile,
    Bank,
  },
  data() {
    return {
      config: {
        uri: `/${this.$route.meta.link}`,
      },
    }
  },
}
</script>

<style>

</style>
