<template>
  <validation-observer ref="formProfile">
    <form
      @submit.prevent="save"
    >
      <div class="animated fadeIn">
        <b-card class="px-md-2">
          <ResponseAlert ref="response" />
          <b-row class="mt-2">
            <b-col
              sm="12"
              md="10"
              lg="9"
            >
              <b-row
                class="my-1"
                align-v="center"
              >
                <b-col sm="3">
                  <label
                    class="h5"
                    for="name"
                  >Photo</label>
                </b-col>
                <b-col
                  sm="9"
                  lg=""
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Photo"
                    :rules="vars.rulesImage"
                  >
                    <div class="d-flex align-items-center flex-column flex-lg-row">

                      <b-img
                        :src="getUrlOfFile(vars.photo)"
                        class="mr-md-2 mb-2 mb-md-0 img-fluid"
                        style="max-height: 250px"
                      />
                      <div class="d-flex flex-column">
                        <label
                          for="file-input"
                          class="btn btn-primary"
                          style="flex: none;"
                        >
                          <input
                            id="file-input"
                            type="file"
                            accept="image/png, image/gif, image/jpeg, image/jpg"
                            hidden
                            @change="AddPhoto"
                          >
                          <feather-icon icon="PlusIcon" />
                          Upload Photo
                        </label>
                        <input
                          v-model="vars.photo"
                          type="hidden"
                          :state="errors.length > 0 ? false:null"
                        >
                        <small class="text-danger text-center d-block m-auto">{{ errors[0] }}</small>

                      </div>
                    </div>
                  </validation-provider>
                </b-col>

              </b-row>
              <b-row
                class="my-1"
                align-v="baseline"
              >
                <b-col sm="3">
                  <label
                    class="h5"
                    for="name"
                  >Nama</label>
                </b-col>
                <b-col sm="9">
                  <validation-provider
                    #default="{ errors }"
                    name="Nama"
                    rules="required"
                  >
                    <b-form-input
                      id="name"
                      v-model="models.fullName"
                      type="text"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>

                  </validation-provider>
                </b-col>

              </b-row>
              <b-row
                class="my-1"
                align-v="baseline"
              >
                <b-col sm="3">
                  <label
                    class="h5"
                    for="email"
                  >Email</label>
                </b-col>
                <b-col sm="9">
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="email"
                      v-model="models.email"
                      type="email"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row
                class="my-1"
                align-v="baseline"
              >
                <b-col sm="3">
                  <label
                    class="h5"
                    for="phone_number"
                  >Nomor HP</label>
                </b-col>
                <b-col sm="9">
                  <validation-provider
                    #default="{ errors }"
                    name="Nomor HP"
                    rules="required|numeric"
                  >
                    <b-form-input
                      id="phone_number"
                      v-model="models.phone"
                      type="tel"
                      :state="errors.length > 0 ? false:null"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>

                  </validation-provider>
                </b-col>
              </b-row>
              <div class="d-flex justify-content-center justify-content-md-end mt-2 mt-md-0">
                <b-button
                  type="button"
                  variant="light"
                  class="btn-min-width rounded"
                  @click="setModels"
                >
                  Batal
                </b-button>
                <b-button
                  v-if="!isDetailPage"
                  type="submit"
                  variant="primary"
                  class="btn-min-width rounded ml-1"
                >
                  Simpan
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </div>
    </form>
  </validation-observer>
</template>

<script>
import api from '@/utils/api'
import { uploadFile, getUrlOfFile } from '@/utils/helpers'

/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, email, numeric, image,
} from '@validations'
import {
  BCard, BRow, BCol, BButton, BImg, BFormInput,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line import/no-cycle

export default {
  name: 'FormProfile',
  metaInfo: {
    title: 'Profile',
  },
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BImg,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      config: {
        uri: `/${this.$route.meta.link}`,
      },
      models: {
        fullName: '',
        email: '',
        photo: '',
        phone: '',
      },
      vars: {
        rulesImage: 'required|image',
        photo: null,
      },
      // Functions
      getUrlOfFile,
      required,
      email,
      numeric,
      image,
    }
  },
  computed: {
    isDetailPage() {
      return this.$route.name === 'detail-admin'
    },
    imageField() {
      return this.vars.photo
    },
    user() {
      return this.$store.state.user.me
    },
  },
  watch: {
    imageField() {
      if (typeof this.vars.photo !== 'string') {
        this.vars.rulesImage = 'required|image'
      }
    },
  },
  created() {
    this.setModels()
    this.vars.rulesImage = 'required'
  },
  methods: {
    setModels() {
      this.models.photo = this.user.profile.photo
      this.vars.photo = this.user.profile.photo
      this.models.fullName = this.user.profile.fullName
      this.models.phone = this.user.profile.phone
      this.models.email = this.user.email
    },
    AddPhoto(event) {
      const fileSizeLimit = 1024 * 1024 * 2 // limit 2 MB
      if (event.target.files[0].size < fileSizeLimit) {
        const photo = event.target.files[0]
        this.vars.photo = photo
        // eslint-disable-next-line no-param-reassign
        event.target.value = null
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Failed',
            text: 'Max file size is 2 MB',
            icon: 'XIcon',
            variant: 'danger',
          },
        })
      }
    },
    save() {
      this.$refs.formProfile.validate().then(async success => {
        if (success) {
          // get file image url
          this.models.photo = typeof this.vars.photo === 'string' ? this.vars.photo : await uploadFile(this.vars.photo)

          this.$axios.put(`${api.me}/profile`, this.models)
            .then(() => {
              this.$axios.get(api.me).then(res => {
                this.$store.dispatch('user/updateUserInfo', res.data.message)
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Success update profile',
                    icon: 'CheckCircleIcon',
                    variant: 'success',
                  },
                })
              })
            })
            .catch(error => this.$refs.response.setResponse(error.response.data, 'danger'))
        } else {
          window.scrollTo(0, 0)
        }
      })
    },
  },
}
</script>

<style>

</style>
